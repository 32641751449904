import React from 'react';

const features = [
    {
        title: 'Quality Results',
        description: 'We deliver exceptional quality results through meticulous attention to detail and a commitment to excellence. Our team ensures that every project meets the highest standards, providing you with reliable and superior outcomes.',
        imgSrc: 'images/quality-results.png'
    },
    {
        title: 'Analytics',
        description: 'Our comprehensive analytics services offer actionable insights to drive informed decision-making and optimize performance. Utilizing advanced tools and methodologies, we analyze data to deliver clear, impactful results that help you achieve your business objectives.',
        imgSrc: 'images/analytics.png'
    },
    {
        title: 'Affordable Pricing',
        description: 'We offer competitive and transparent pricing for our services without compromising on quality. Our solutions are designed to provide maximum value within your budget, ensuring cost-effectiveness while delivering outstanding performance.',
        imgSrc: 'images/affordable-pricing.png'
    },
    {
        title: 'Easy To Use',
        description: 'Our solutions are user-friendly and intuitive, ensuring a seamless experience for you and your team. We prioritize ease of use in every product we develop, enabling you to adopt new technologies with minimal disruption.',
        imgSrc: 'images/easy-to-use.png'
    },
    {
        title: 'Free Support',
        description: 'We provide comprehensive and free support to ensure your success. Our dedicated support team is always ready to assist you with any questions or issues you may encounter, offering prompt and effective solutions to keep your operations running smoothly.',
        imgSrc: 'images/free-support.png'
    },
    {
        title: 'Effectively Increase',
        description: 'Our services are designed to effectively increase your business’s efficiency and productivity. We implement strategies and solutions that drive growth and improve performance, ensuring that your business reaches its full potential.',
        imgSrc: 'images/effectively-increase.png'
    },
];


const Experties = () => (
    <div className="container-fluid gtco-features-list">
        <div className="container">
        <h2 className="text-center mb-3">Why Choose US?</h2>
            <div className="row">
                {features.map((feature, index) => (
                    <div className="media col-md-6 col-lg-4" key={index}>
                        <div className="oval mr-4">
                            <img className="align-self-start" src={feature.imgSrc} alt={feature.title} />
                        </div>
                        <div className="media-body">
                            <h5 className="mb-0">{feature.title}</h5>
                            {feature.description}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default Experties;
