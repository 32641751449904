import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
const testimonials = [
    {
        name: 'Rahul Sharma',
        role: 'Project Manager',
        imgSrc: 'images/customer1.jpg',
        text: '“ The team at PIP TRADE delivered exceptional results on our project, exceeding our expectations. Their professionalism and attention to detail are truly commendable. ”',
    },
    {
        name: 'Priya Verma',
        role: 'Product Manager',
        imgSrc: 'images/customer2.jpg',
        text: '“ Working with PIP TRADE has been a seamless experience. Their innovative solutions and dedicated support have significantly enhanced our business operations. ”',
    },
    {
        name: 'Amit Patel',
        role: 'IT Director',
        imgSrc: 'images/customer3.jpg',
        text: '“ PIP TRADE provided us with outstanding service, from initial consultation to final delivery. Their expertise and commitment to quality are evident in every aspect of their work. ”',
    },
    {
        name: 'Neha Singh',
        role: 'Operations Manager',
        imgSrc: 'images/customer4.jpg',
        text: '“ The collaboration with PIP TRADE was a game-changer for our project. Their insights and solutions were invaluable in achieving our goals. ”',
    },
    {
        name: 'Vikram Desai',
        role: 'Chief Technology Officer',
        imgSrc: 'images/customer5.jpg',
        text: '“ I highly recommend PIP TRADE for their exceptional service and innovative solutions. They consistently deliver high-quality results that drive success. ”',
    },
    {
        name: 'Anjali Kapoor',
        role: 'Marketing Manager',
        imgSrc: 'images/customer6.jpg',
        text: '“ PIP TRADE has been an integral part of our digital transformation journey. Their expertise and support have been instrumental in our success. ”',
    },
];


const Testimonials = () => (
    <div className="container-fluid gtco-testimonials">
        <div className="container">
            <h2>What our customers say about us.</h2>
            <OwlCarousel 
                className="owl-carousel1 owl-theme" 
                responsive={{"0":{items:1},"600":{items:2},"1000":{items:3}}}
                center={true} 
                autoplay={true} 
                loop={true} 
                margin={1}
                nav={true}
                dots={true}
            >
                {testimonials.map((testimonial, index) => (
                    <div key={index} className="item">
                        <div className="card text-center">
                            {/* <img className="card-img-top" src={testimonial.imgSrc} alt={testimonial.name} /> */}
                            <div className="card-body">
                                <h5>{testimonial.name}<br />
                                    <span>{testimonial.role}</span>
                                </h5>
                                <p className="card-text">{testimonial.text}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </OwlCarousel>
        </div>
    </div>
);

export default Testimonials;
