import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Footer() {

  const [formData, setFormData] = useState({
    fullName: '',
    phone: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { fullName, phone, email, message } = formData;

    const to = "hello@piptrade.org";
    const subject = `New message from ${fullName}`;
    const text = `From: ${fullName} | Phone: ${phone} | Email: ${email}\nMessage: ${message}`;

    fetch('https://app.betatrader11.com/send-mail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ to, subject, text })
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          alert('Error: ' + data.error);
          setFormData({ fullName: '', phone: '', email: '', message: '' }); // Reset form
        } else {
          alert('Email sent successfully!');
          setFormData({ fullName: '', phone: '', email: '', message: '' }); // Reset form
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  return (
    <footer className="container-fluid" id="gtco-footer">
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-6" id="contact">
            {/* <h4>Ready to Grow? Let’s Get Started!</h4> */}
            <h4>Let's Create Something Amazing Together!</h4>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="fullName"
                className="form-control"
                placeholder="Full Name"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="phone"
                className="form-control"
                placeholder="Phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email Address"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <textarea
                name="message"
                className="form-control"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
              <button type="submit" className="submit-button">
                Send Us <i className="fa fa-angle-right" aria-hidden="true"></i>
              </button>
            </form>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className='col-12'>
                <h4>Our Location</h4>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.7144356742074!2d77.0175864754245!3d28.60834247567797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xafc03ae39aca99a1%3A0xcaa26ee45685650c!2sPIP%20TRADE!5e0!3m2!1sen!2sin!4v1722234300833!5m2!1sen!2sin" width="100%" height="400" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Our Location'></iframe>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className="col-md-3 about-pip mb-3 mb-md-0">
            <h4 className='mb-1 mb-md-3'>About PIP TRADE</h4>
            <img className='mx-auto d-block' src='piptrade.png' alt='piptrade-logo' />
            <h6 className='company-nav'>"Your Vision, Our Innovation"</h6>
          </div>
          <div className="col-md-3 contact-us mb-5 mb-md-0 company-nav">
            <h4 className='mb-1 mb-md-3'>How to Reach Us</h4>
            <h5>
              <a href='https://maps.app.goo.gl/DsWWwTpWFXgqi3f59' target='_blank' rel='noreferrer'>
                <i className="bi bi-geo-alt-fill mr-3" aria-hidden="true"></i>Ground floor, 81,<br/>Pocket-3C, SEC-16B, DWARKA, South West Delhi,<br/>Delhi-110078
              </a>
            </h5>
            <h5>
              <a href="mailto:hello@piptrade.org">
                <i className="fa fa-envelope mr-3" aria-hidden="true"></i> hello@piptrade.org
              </a>
            </h5>
            <h5 className='mb-5'>
              <a href='tel:+918448117295'>
                <i className="fa fa-phone mr-3 mt-3" aria-hidden="true"></i> +91 8448117295
              </a>
            </h5>
          </div>
          <div className="col-md-3 mb-3 mb-md-0">
            <h4 className='mb-1 mb-md-3'>Company</h4>
            <ul className="nav flex-column company-nav">
              <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/about">About</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/services">Services</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/products">Products</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/portfolio">Portfolio</Link></li>
              <li className="nav-item"><Link className="nav-link" to="https://blog.piptrade.org" target='_blank'>Blog</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/careers">Careers</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/contact">Contact Us</Link></li>
            </ul>
          </div>
          <div className="col-md-3 mb-3 mb-md-0">
            <h4 className='mb-1 mb-md-3'>Services</h4>
            <ul className="nav flex-column services-nav">
              <li className="nav-item"><Link className="nav-link" to="/services">Web Development</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/services">Mobile App Development</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/services">Desktop App Development</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/services">CRM Development</Link></li>
              <li className="nav-item"><Link className="nav-link" to="/services">SEO</Link></li>
            </ul>
          </div>
        </div>

        <div className='mt-3 d-flex justify-content-between flex-wrap'>
          <div className="">
            <ul className="nav follow-us-nav">
              <li className="nav-item"><a className="nav-link pl-0" href="https://www.instagram.com/piptrade_org"  aria-label='Instagram'><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
              <li className="nav-item"><a className="nav-link" href="mailto:hello@piptrade.org" aria-label='Email'><i className="fa fa-google" aria-hidden="true"></i></a></li>
              <li className="nav-item"><a className="nav-link" href="https://www.linkedin.com/company/piptrade-org/" aria-label='LinkedIn'><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
            </ul>
          </div>
          <div>
            <p>&copy; 2024 All Rights Reserved. <a href="/" className='company'>PIP TRADE</a></p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
