import React from 'react';
import { Link } from 'react-router-dom';

function Banner() {
    return (
        <div className="container-fluid gtco-banner-area">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h1>
                            We promise to bring the best <span>solution</span> for your business.
                        </h1>

                        <h2 style={{
                            background: "-webkit-linear-gradient(0deg, #06c6f9 0%, #38eaf9 100%)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            marginTop:"16px"
                        }}>
                            Elevating Brands with Every Project We Undertake !
                        </h2>
                        <p>
                            Our expert team combines deep industry knowledge with cutting-edge technology to deliver software that not only meets but exceeds your expectations.
                        </p>
                        <Link to="/contact">Contact Us <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <img className="card-img-top img-fluid" src="images/banner-img.png" alt="Banner" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;
