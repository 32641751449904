import React from 'react';
import { Link } from 'react-router-dom';

const Services = () => (
    <div className="container-fluid gtco-features" id="about">
        <div className="container">
            <div className="row">
                <div className="col-lg-4">
                    <h2> Explore The Services<br />
                        We Offer For You </h2>
                    <p>At PIP TRADE, we don’t just build software; we craft digital experiences that transform your vision into reality. With a commitment to excellence and a passion for innovation, our expert team is here to elevate your business through cutting-edge technology and bespoke solutions.</p>
                    <Link to="/services">All Services <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                </div>
                <div className="col-lg-8">
                    <svg id="bg-services" width="100%" viewBox="0 0 1000 800">
                        <defs>
                            <linearGradient id="PSgrad_02" x1="64.279%" x2="0%" y1="76.604%" y2="0%">
                                <stop offset="0%" stop-color="rgb(1,230,248)" stop-opacity="1" />
                                <stop offset="100%" stop-color="rgb(29,62,222)" stop-opacity="1" />
                            </linearGradient>
                        </defs>
                        <path fill-rule="evenodd" opacity="0.102" fill="url(#PSgrad_02)"
                            d="M801.878,3.146 L116.381,128.537 C26.052,145.060 -21.235,229.535 9.856,312.073 L159.806,710.157 C184.515,775.753 264.901,810.334 338.020,792.380 L907.021,652.668 C972.912,636.489 1019.383,573.766 1011.301,510.470 L962.013,124.412 C951.950,45.594 881.254,-11.373 801.878,3.146 Z" />
                    </svg>
                    <div className="row">
                        <div className="col">
                            <div className="card text-center">
                                <div className="oval"><img className="card-img-top" src="images/web-design.png" alt="" /></div>
                                <div className="card-body">
                                    <h3 className="card-title">Web Development</h3>
                                    <p className="card-text">Our web development services ensure that your online presence is not only functional but exceptional.</p>
                                </div>
                            </div>
                            <div className="card text-center">
                                <div className="oval"><img className="card-img-top" src="images/desktop.png" alt="" /></div>
                                <div className="card-body">
                                    <h3 className="card-title">Desktop App Development</h3>
                                    <p className="card-text">Whether you need an enterprise solution or a consumer app, our robust, efficient, and user-friendly desktop applications software meets your unique needs.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card text-center">
                                <div className="oval"><img className="card-img-top" src="images/mobile.png" alt="" /></div>
                                <div className="card-body">
                                    <h3 className="card-title">Mobile App Development</h3>
                                    <p className="card-text">We develop intuitive and high-performance mobile apps that provide a seamless user experience on both iOS and Android platforms.</p>
                                </div>
                            </div>
                            <div className="card text-center">
                                <div className="oval"><img className="card-img-top" src="images/CRM.png" alt="" />
                                </div>
                                <div className="card-body">
                                    <h3 className="card-title">CRM Development</h3>
                                    <p className="card-text">We develop custom CRM systems that integrate seamlessly with your existing processes, providing powerful tools to manage and nurture your customer relationships.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Services;
