import React from 'react'

function Contact() {
  return (
    <div>
      <div className="container-fluid gtco-banner-area">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>
                At <span>PIP TRADE</span>, we are committed to providing exceptional support and assistance.
              </h1>

              <h2 style={{
                background: "-webkit-linear-gradient(0deg, #06c6f9 0%, #38eaf9 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginTop: "16px"
              }}>
                Connect with us for innovative solutions !
              </h2>
              <p>
              Whether you have questions about our services, need technical support, or just want to get in touch, we're here to help.
              </p>
            </div>
            <div className="col-md-6">
              <div className="card">
                <img className="card-img-top img-fluid" src="images/banner-img.png" alt="Banner" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact