import React from 'react'
import { Link } from 'react-router-dom'
import JobCard from '../components/JobCard'

const benefits = [
  {
    title: 'Innovative Culture',
    description: 'We foster a culture of creativity and innovation, encouraging our team members to think outside the box and push boundaries.'
  },
  {
    title: 'Career Development',
    description: 'We invest in our employees\' growth through continuous learning opportunities, mentorship programs, and professional development.'
  },
  {
    title: 'Collaborative Environment',
    description: 'Join a team where your ideas are valued, and collaboration is at the heart of everything we do.'
  },
  {
    title: 'Work-Life Balance',
    description: 'We offer flexible working arrangements and support for a healthy work-life balance to ensure our employees thrive both professionally and personally.'
  },
  {
    title: 'Competitive Benefits',
    description: 'Enjoy a comprehensive benefits package, including health insurance, retirement plans, and performance-based bonuses.'
  },
  {
    title: 'Diverse and Inclusive Workplace',
    description: 'We are committed to creating a diverse and inclusive environment where all employees feel respected, valued, and empowered to contribute their best.'
  }
];

const jobs = [
  {
    title: 'Software Developer',
    location: 'Dwarka, Delhi',
    description: 'We are seeking a skilled Software Developer to join our dynamic team. The ideal candidate will have a strong background in React and Node.js, with a passion for building innovative applications. You will play a key role in developing and maintaining high-performance software solutions, working on exciting and challenging projects that push the boundaries of technology.'
  },
  {
    title: 'Java Developer',
    location: 'Dwarka, Delhi',
    description: 'We are on the lookout for a talented Java Developer to contribute to our software development team. The successful candidate will have extensive experience with Java and related technologies, and a drive to create scalable and efficient applications. You will be involved in designing, developing, and optimizing software systems, collaborating closely with other developers to deliver high-quality solutions.'
  }
];


function Careers() {
  return (
    <div>
      <div className="container-fluid gtco-banner-area">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>
                At <span>PIP TRADE</span>, we believe that our greatest asset is our team.
              </h1>

              <h2 style={{
                background: "-webkit-linear-gradient(0deg, #06c6f9 0%, #38eaf9 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginTop: "16px"
              }}>
                Empowering Innovation, Fostering Growth !
              </h2>
              <p>
                We are dedicated to creating a dynamic and inclusive work environment where creativity, innovation, and personal growth are encouraged.
              </p>
              <Link to="/contact">Contact Us <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
            </div>
            <div className="col-md-6">
              <div className="card">
                <img className="card-img-top img-fluid" src="images/banner-img.png" alt="Banner" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid gtco-features-list">
        <div className="container">
          <h2 className="text-center mb-3">Why Work With US?</h2>
          <div className="row">
            {benefits.map((feature, index) => (
              <div className="media col-md-6 col-lg-4" key={index}>
                <div className="media-body">
                  <h5 className="mb-0">{feature.title}</h5>
                  {feature.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="container mt-5">
      <h2 className="text-center mb-5">Current Openings</h2>
      <JobCard jobs={jobs} />
    </div>

    </div>
  )
}

export default Careers