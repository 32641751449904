import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
    const handleToggle = () => {
        const nav = document.getElementById('my-nav');
        nav.classList.toggle('collapse');
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light bg-transparent" id="gtco-main-nav">
            <div className="container">
                <a href='/' className="navbar-brand"><img src='piptrade.png' alt='PIP TRADE LOGO' width="60px"/></a>
                <button className="navbar-toggler" onClick={handleToggle} data-toggle="collapse">
                    <span className="bar1"></span>
                    <span className="bar2"></span>
                    <span className="bar3"></span>
                </button>
                <div id="my-nav" className="collapse navbar-collapse">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item" onClick={handleToggle}>
                            <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item" onClick={handleToggle}>
                            <Link className="nav-link" to="/about">About</Link>
                        </li>
                        <li className="nav-item" onClick={handleToggle}>
                            <Link className="nav-link" to="/services">Services</Link>
                        </li>
                        <li className="nav-item" onClick={handleToggle}>
                            <Link className="nav-link" to="/products">Products</Link>
                        </li>
                        <li className="nav-item" onClick={handleToggle}>
                            <Link className="nav-link" to="/portfolio">Portfolio</Link>
                        </li>
                        <li className="nav-item" onClick={handleToggle}>
                            <Link className="nav-link" to="https://blog.piptrade.org" target='_blank'>Blog</Link>
                        </li>
                        <li className="nav-item" onClick={handleToggle}>
                            <Link className="nav-link" to="/careers">Careers</Link>
                        </li>
                        <li className="nav-item" onClick={handleToggle}>
                            <Link className="nav-link" to="/contact">Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Header;
