import React from 'react';
import './JobPostCard.css';

const JobPostCard = ({ jobs }) => {
    const handleApply = (jobTitle) => {
        const subject = `Job application for ${jobTitle}`;
        const body = 'Please upload your resume';
        const mailtoLink = `mailto:hello@piptrade.org?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        window.location.href = mailtoLink;
    };

    return (
        <div className="container mt-5">
            <div className="row">
                {jobs.map((job, index) => (
                    <div key={index} className="col-md-4 mb-4 mx-auto">
                        <div className="job-post-card">
                            <div className="card-body text-center">
                                <h5 className="card-title">{job.title}</h5>
                                <h6 className="card-subtitle mb-2 text-muted">{job.location}</h6>
                                <p className="card-text">
                                    {job.description}
                                </p>
                                <button className="apply-button" onClick={() => handleApply(job.title)}>
                                    Apply Now
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default JobPostCard;
