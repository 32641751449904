import React from 'react'
import Counter from '../components/Counter'
import Testimonials from '../components/Testimonials'
import Experties from '../components/Experties'
import { Link } from 'react-router-dom'
import './ServicesPage.css';

const services = [
  {
    title: 'Web Development',
    description: 'We specialize in creating stunning, responsive websites that captivate and engage your audience. Our web development services are designed to provide a seamless user experience while delivering exceptional results.',
    technologies: [
      { name: 'HTML5', icon: 'fa fa-html5' },
      { name: 'CSS3', icon: 'bi bi-filetype-css' },
      { name: 'JavaScript', icon: 'bi bi-filetype-js' },
      { name: 'React', icon: 'bi bi-filetype-jsx' },
      { name: 'PHP', icon: 'bi bi-filetype-php' },
      { name: 'Bootstrap', icon: 'bi bi-bootstrap' },
      { name: 'Tailwind CSS', icon: 'fa fa-code' }
    ],
    icon: 'images/web-design.png'
  },
  {
    title: 'Mobile App Development',
    description: 'Our mobile app development services focus on delivering high-performance apps for both iOS and Android platforms. We ensure your app is intuitive, functional, and designed to provide a superior user experience.',
    technologies: [
      { name: 'iOS', icon: 'fa fa-apple' },
      { name: 'Android', icon: 'fa fa-android' },
      { name: 'React Native', icon: 'fa fa-code' },
      { name: 'Swift', icon: 'fa fa-code' },
      { name: 'Kotlin', icon: 'fa fa-code' }
    ],
    icon: 'images/mobile.png'
  },
  {
    title: 'Desktop Application Development',
    description: 'We build robust desktop applications that enhance productivity and streamline your operations. Our solutions are designed to be user-friendly and tailored to meet your business needs.',
    technologies: [
      { name: 'Windows', icon: 'fa fa-code' },
      { name: 'macOS', icon: 'fa fa-code' },
      { name: 'Java', icon: 'fa fa-code' },
      { name: 'C#', icon: 'fa fa-code' },
      { name: 'Electron', icon: 'fa fa-code' }
    ],
    icon: 'images/desktop.png'
  },
  {
    title: 'CRM Development',
    description: 'Our custom CRM solutions are designed to help you manage and nurture customer relationships effectively. We create systems that integrate seamlessly with your business processes, providing valuable insights and improving customer satisfaction.',
    technologies: [
      { name: 'SQL', icon: 'fa fa-code' },
      { name: 'PHP', icon: 'fa fa-code' },
      { name: 'Python', icon: 'fa fa-code' },
      { name: 'Cloud Integration', icon: 'fa fa-code' },
      { name: 'API Integration', icon: 'fa fa-code' }
    ],
    icon: 'images/CRM.png'
  },
  {
    title: 'SEO Optimization',
    description: 'Our SEO optimization services are designed to improve your website’s visibility in search engines, driving organic traffic and increasing your online presence.',
    technologies: [
      { name: 'Keyword Research', icon: 'fa fa-search' },
      { name: 'On-Page SEO', icon: 'fa fa-tag' },
      { name: 'Off-Page SEO', icon: 'fa fa-link' },
      { name: 'Analytics', icon: 'bi bi-graph-up-arrow' }
    ],
    icon: 'images/search-engine-optimization.png'
  },
  {
    title: 'Cloud Deployment',
    description: 'We provide cloud deployment services to ensure your applications are hosted efficiently and securely, leveraging the scalability and flexibility of cloud infrastructure.',
    technologies: [
      { name: 'AWS', icon: 'bi bi-amazon' },
      { name: 'Azure', icon: 'bi bi-microsoft' },
      { name: 'Google Cloud', icon: 'fa fa-google' },
      { name: 'Docker', icon: 'fa fa-code' }
    ],
    icon: 'images/deployment.png'
  },
  {
    title: 'Website Hosting',
    description: 'Our website hosting services offer reliable and scalable hosting solutions to ensure your website is always online, performing optimally, and accessible to your audience.',
    technologies: [
      { name: 'Shared Hosting', icon: 'fa fa-server' },
      { name: 'VPS Hosting', icon: 'fa fa-cogs' },
      { name: 'Dedicated Hosting', icon: 'fa fa-database' },
      { name: 'Cloud Hosting', icon: 'fa fa-cloud' }
    ],
    icon: 'images/web-hosting.png'
  }
];

function Services() {

  return (
    <div>
      <div className="container-fluid gtco-banner-area">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>We deliver customized technology solutions that drive business success</h1>
              <h2 style={{
                background: "-webkit-linear-gradient(0deg, #06c6f9 0%, #38eaf9 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginTop: "16px"
              }}>
                Innovate, Transform, Achieve<br />with PIP TRADE, the future is yours.
              </h2>
              <Link to="/contact">Contact Us <i className="fa fa-angle-right mt-3" aria-hidden="true"></i></Link>
            </div>
            <div className="col-md-6">
              <div className="card">
                <img className="card-img-top img-fluid" src="images/banner-img.png" alt="Banner" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container service-section">
        <h2 className="text-center mb-5">Our Services</h2>
        <div className="row">
          {services.map((service, index) => (
            <div key={index} className="col-md-6 col-lg-4 mb-4 mx-auto">
              <div className="service-card text-center">
                <img src={service.icon} width={100} className='d-block mx-auto' alt={service.title}/>
                <h3 className="service-title">{service.title}</h3>
                <p className="service-description">
                  {service.description}
                </p>
                <ul className="technology-list">
                  {service.technologies.map((tech, index) => (
                    <li key={index}>
                      <i className={tech.icon}></i> {tech.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Experties />
      <Counter />
      <Testimonials />
    </div>
  )
}

export default Services