import React from 'react'
import Banner from '../components/Banner'
import Counter from '../components/Counter'
import Testimonials from '../components/Testimonials'
import Experties from '../components/Experties'
import Services from '../components/Services'
import About from '../components/About'

function Home() {
  return (
    <>
      <Banner />
      <About />
      <Services />
      <Experties />
      <Counter />
      <Testimonials />
    </>
  )
}

export default Home