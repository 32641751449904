import React from 'react'
import Counter from '../components/Counter'
import Testimonials from '../components/Testimonials'
import Experties from '../components/Experties'
import Services from '../components/Services'
import { Link } from 'react-router-dom'

function Portfolio() {
  return (
    <div>
      <div className="container-fluid gtco-banner-area">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1>
                At <span>PIP TRADE</span>, we take pride in the diverse and impactful projects we have delivered.
              </h1>

              <h2 style={{
                background: "-webkit-linear-gradient(0deg, #06c6f9 0%, #38eaf9 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                marginTop: "16px"
              }}>
                Where Creativity Meets Technology, and Ideas Come to Life !
              </h2>
              <p>
                Our portfolio highlights the depth of our expertise and the range of industries we serve. Each project reflects our commitment to excellence, innovation, and client satisfaction.
              </p>
              <Link to="/contact">Contact Us <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
            </div>
            <div className="col-md-6">
              <div className="card">
                <img className="card-img-top img-fluid" src="images/banner-img.png" alt="Banner" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Services />
      <Experties />
      <Counter />
      <Testimonials />
    </div>
  )
}

export default Portfolio