import React from 'react';
import './Products.css';
import { Link } from 'react-router-dom';

const products = [
    {
        title: 'Web Trading Platform',
        description: 'Harness the power of automation to execute trades with precision and speed. Our automated trading systems are designed to optimize performance and reduce risk.',
        imgSrc: 'images/web-trading.png',
        link:"https://trade.betatrader11.com",
        linkDesc:"Visit Web Terminal"
    },
    {
        title: 'Moblie Trading Platform',
        description: 'Develop and implement sophisticated trading algorithms tailored to your strategies. Our algorithms are built to maximize profitability and minimize losses.',
        imgSrc: 'images/mobile-trading.png',
        link:"https://betatrader11.com/client/downloads/beta_trader_apk.php",
        linkDesc:"Download APK"
    },
    {
        title: 'Desktop Trading Platform',
        description: 'Gain insights and make informed decisions with our comprehensive market analysis tools. Stay ahead of market trends and identify profitable opportunities.',
        imgSrc: 'images/desktop-trading.png',
        link:"https://betatrader11.com/client/downloads/beta_trader_desktop_terminal.php",
        linkDesc:"Download Desktop Terminal"
    }
];

const Products = () => {
    return (
        <div>
            <div className="container-fluid gtco-banner-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h1>
                                We promise to build the best <span>Trading Solution</span> for your business.
                            </h1>

                            <h2 style={{
                                background: "-webkit-linear-gradient(0deg, #06c6f9 0%, #38eaf9 100%)",
                                WebkitBackgroundClip: "text",
                                WebkitTextFillColor: "transparent",
                                marginTop: "16px"
                            }}>
                                Empowering Your Trading Success with Cutting-Edge Technology !
                            </h2>
                            <p>
                                Our expert team combines deep industry knowledge with cutting-edge technology to deliver software that not only meets but exceeds your expectations.
                            </p>
                            <Link to="/contact">Contact Us <i className="fa fa-angle-right" aria-hidden="true"></i></Link>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <img className="card-img-top img-fluid" src="images/banner-img.png" alt="Banner" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container product-section">
                <h2 className="text-center mb-5 bold"><strong>Our Trading Products</strong></h2>
                <div className="row">
                    {products.map((product, index) => (
                        <div key={index} className="col-md-6 col-lg-4">
                            <div className="card product-card text-center gtco-banner-area">
                                <img src={product.imgSrc} className="card-img-top mx-auto" alt={product.title} style={{ objectFit: "contain", width: "100%" }} />
                                <div className="card-body">
                                    <h5 className="card-title">{product.title}</h5>
                                    <p className="card-text">{product.description}</p>
                                </div>
                                <div className='d-block pb-5' ><Link to={product.link} target='_blank'>{product.linkDesc}<i className="fa fa-angle-right" aria-hidden="true"></i></Link></div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Products;
